import { ContractKit } from '@wharfkit/contract';
import React from 'react';
import "./css/BurnButton.css";
const BurnButton = ({ data, session, setTransactionHash, refreshAssets, setSelectedAssets, setShowPopup, refreshLeaderboard }) => {
    const handleBurnNFTs = async () => {
        const contractKit = new ContractKit({
            client: session.client,
        });

        const contract = await contractKit.load("atomicassets");

        // Create a list of actions
        const actions = data.map((assetId) => {
            return contract.action("burnasset", {
                asset_owner: session.actor,
                asset_id: assetId,
            });
        });

        try {
            // Perform all actions in a single transaction
            const result = await session.transact({ actions });
            const txHash = result.response.transaction_id; // Get the transaction hash
            setTransactionHash(txHash); // Set the transaction hash for the popup

            // Set the transaction link (adjust this URL as necessary)
            const transactionLink = `https://waxblock.io/transaction/${txHash}`;
            setShowPopup(true, transactionLink); // Show the popup and set transaction link

            setSelectedAssets([]); // Deselect assets

            // Refresh assets after burning
            await refreshAssets();
            await refreshLeaderboard();

            console.log("Transaction successful", result);
        } catch (error) {
            console.error("Transaction failed", error);
        }
    };

    return (
        <>
            <button className="BurnButton" onClick={handleBurnNFTs}>
                Burn
            </button>
        </>
    );
};

export default BurnButton;
