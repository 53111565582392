import React, { useState } from 'react';
import "./css/Landing.css";
import Navbar from './widgets/Navbar'; // Import the Navbar component
import Subbar from './widgets/Subbar'; // Import the Subbar component
import Card from "./widgets/Card";

const Landing = ({
    session,
    handleLogout,
    balance,
    schemas,
    filteredAssets,
    schemaFilter,
    setSchemaFilter,
    girls,
    items
}) => {
    const [visibleAssets, setVisibleAssets] = useState(8); // Initial number of assets to show

    // Function to load more assets
    const loadMoreAssets = () => {
        setVisibleAssets(prev => prev + 16); // Load 6 more assets
    };

    return (
        <div className='Landing'>
            {/* Add the Navbar here */}
            <Navbar handleLogout={handleLogout} session={session} balance={balance} />
            <Subbar handleLogout={handleLogout} session={session} balance={balance} items={items} girls={girls} />

            <div style={{ paddingTop: '70px' }}> {/* Adjust this based on your navbar height */}
                <div className="schema-filter">
                    <label htmlFor="schema-select">Filter by Schema:</label>
                    <select
                        id="schema-select"
                        value={schemaFilter || ''}
                        onChange={(e) => setSchemaFilter(e.target.value)}
                    >
                        <option value="">All</option>
                        {schemas.map((schema, index) => (
                            <option key={index} value={schema}>
                                {schema}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="Landing-Container">
                    <div className="assets-grid-dashboard">
                        {/* Limit the number of visible assets */}
                        {filteredAssets.slice(0, visibleAssets).map((asset) => (
                            <Card key={asset.asset_id} asset={asset} />
                        ))}
                    </div>

                    {/* Display the Load More button only if there are more assets to load */}
                    {visibleAssets < filteredAssets.length && (
                        <button className="load-more" onClick={loadMoreAssets}>
                            Load More Assets
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Landing;
