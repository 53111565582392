
import './css/footer.css';
import ncLogo from './assets/nightclublogo.png';
import xLogo from "./assets/x.png"
import tgLogo from "./assets/tg.png"
import webLogo from "./assets/web.png"

const Foot = () => {

    return (
        <nav className="foot">
            <div className="foot-left">
                <img src={ncLogo} alt="Night Club NFT Logo" width="40" height="40" style={{ marginRight: '5px' }} />
                Night Club NFT
            </div>
            <div className="foot-center">
                <p>
                    Copyright © 2024 Night Club Game. All rights reserved.
                </p>
            </div>
            <div className="foot-right">
                <div className='social-widgets'>
                    <a href="https://x.com/NightClubGame?t=LwUNu9ZTNOrjfy17P42Tow&s=09" target="_blank" rel="noopener noreferrer">
                        <img src={xLogo} alt="X Logo" width="20" height="20" style={{ marginRight: '5px' }} />
                    </a>
                    <a href="https://t.me/NightClubGameNft" target="_blank" rel="noopener noreferrer">
                        <img src={tgLogo} alt="Telegram Logo" width="20" height="20" style={{ marginRight: '5px' }} />
                    </a>
                    <a href="https://nightclubgame.info" target="_blank" rel="noopener noreferrer">
                        <img src={webLogo} alt="Website Logo" width="20" height="20" style={{ marginRight: '5px' }} />
                    </a>

                </div>

            </div>
        </nav>
    );
};



export default Foot;
