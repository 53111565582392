// src/TestnetApp.js
import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom'; // For navigation
import "./css/App.css";
import { ContractKit } from '@wharfkit/contract';
import Navbar from './widgets/Navbar'; // Import the Navbar component
import { fetchBalance } from './funcs/balFetch';

const TestnetApp = ({ session, handleLogout }) => {
    const [leaderboard, setLeaderboard] = useState([]);
    const [visibleEntries, setVisibleEntries] = useState(10); // Initial number of entries to show
    const [bal, setBal] = useState({});

    const fetchPointsData = useCallback(async () => {
        if (!session) return;

        const contractKit = new ContractKit({
            client: session.client,
        });

        try {
            /*const token = await contractKit.load("eosio.token");
            const waxBal = token.table("accounts", session.actor);
            const userbal = await Promise.all([waxBal.get(0)]);
            console.log(JSON.stringify(userbal));
            if (userbal[0]) {
                const balanceString = userbal[0].balance; // "809.95405051 WAX"
                setBal(balanceString); // Set state for local use
            }*/



            const contract = await contractKit.load("smartburnwax");
            const pointsData = contract.table("points").query();
            const rows = await pointsData.all();

            const leaderboardData = rows.map(entry => ({
                account: entry.account?.name || entry.account || '',
                points: entry.points?.value || entry.points || 0
            }));

            leaderboardData.sort((a, b) => b.points - a.points);

            setLeaderboard(leaderboardData);
        } catch (error) {
            console.error('Error fetching points data:', error);
        }
    }, [session]);

    useEffect(() => {
        fetchPointsData();
        const loadBalance = async () => {
            const bal = await fetchBalance(session);
            setBal(bal); // Set the fetched balance
        };

        loadBalance();

    }, [fetchPointsData, session]);

    const loadMoreEntries = () => {
        setVisibleEntries(prev => prev + 10); // Load 10 more entries
    };

    return (
        <div className='Testnet'>
            {/* Add the Navbar here */}
            <Navbar handleLogout={handleLogout} session={session} bal={bal.value} />

            {/* Add padding-top to avoid overlap with the fixed navbar */}
            <div style={{ paddingTop: '70px' }}> {/* Adjust this based on your navbar height */}
                <div className="leaderboard">
                    {leaderboard.slice(0, visibleEntries).map((entry, index) => (
                        <div key={index} className="leaderboard-entry">
                            <span>{index + 1}. {String(entry.account)} - {Number(entry.points)} points</span>
                        </div>
                    ))}
                    {visibleEntries < leaderboard.length && (
                        <button className="load-more" onClick={loadMoreEntries}>
                            Load More
                        </button>
                    )}
                </div>

                {/* Add button to navigate to the NFT Burner */}
                <div className="nft-burner-link">
                    <Link
                        to={{
                            pathname: "/nft-burner",
                            state: { balance: bal } // Ensure this is set correctly
                        }}
                        className="schema-button"
                    >
                        Go to NFT Burner
                    </Link>

                </div>

            </div>
        </div>
    );
};

export default TestnetApp;
