import React, { useEffect } from 'react';
import Navbar from './widgets/Navbar';
import './css/App.css';
import BurnButton from './widgets/BurnButton';
import Card from './widgets/Card'; // Import the new AssetCard component

const NFTBurner = ({
    session,
    handleLogout,
    balance,
    assets,
    selectedAssets,
    setSelectedAssets,
    schemas,
    schemaFilter,
    setSchemaFilter,
    handleSelectAsset,
    visibleAssetsCount,
    loadMoreAssets,
    toggleSelectAll,
    selectAll,
    transactionHash,
    setTransactionHash,
    setShowPopup,
    showPopup,
    handleAssetsRefresh
}) => {
    // Handle schema filtering
    const filteredAssets = schemaFilter
        ? assets.filter(asset => asset.schema.schema_name === schemaFilter)
        : assets;

    // Close popup after a few seconds and re-fetch assets
    useEffect(() => {
        if (showPopup) {
            const timer = setTimeout(() => {
                setShowPopup(false);
                handleAssetsRefresh(); // Re-fetch assets after popup closes
            }, 5000); // Popup disappears after 5 seconds
            return () => clearTimeout(timer);
        }
    }, [handleAssetsRefresh, setShowPopup, showPopup]);

    return (
        <div className="nft-burner">
            <Navbar handleLogout={handleLogout} session={session} balance={balance} />

            <div style={{ paddingTop: '70px' }}>
                <div className="schema-and-burn-container">
                    <div className="schema-filter">
                        <label htmlFor="schema-select">Filter by Schema:</label>
                        <select
                            id="schema-select"
                            value={schemaFilter || ''}
                            onChange={(e) => setSchemaFilter(e.target.value)}
                        >
                            <option value="">All</option>
                            {schemas.map((schema, index) => (
                                <option key={index} value={schema}>
                                    {schema}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="select-buttons">
                        <button onClick={toggleSelectAll}>
                            {selectAll ? 'Deselect All' : 'Select All'}
                        </button>
                    </div>
                    <BurnButton
                        data={selectedAssets}
                        session={session}
                        setTransactionHash={setTransactionHash}
                        setShowPopup={setShowPopup}
                        refreshAssets={handleAssetsRefresh} // Refresh assets after burn
                    />
                </div>

                {/* Transaction Link Display */}
                {transactionHash && (
                    <div className="transaction-link">
                        Transaction: <a href={`https://testnet.waxblock.io/transaction/${transactionHash}`} target="_blank" rel="noopener noreferrer">
                            {transactionHash.slice(0, 6)}...
                        </a>
                    </div>
                )}

                {/* Display filtered assets as cards */}
                <div className="assets-grid">
                    {filteredAssets.slice(0, visibleAssetsCount).map((asset) => (
                        <Card
                            key={asset.asset_id}
                            asset={asset}
                            onSelect={handleSelectAsset}
                            isSelected={selectedAssets.includes(asset.asset_id)}
                        />
                    ))}
                </div>

                {/* Load More Button */}
                {visibleAssetsCount < filteredAssets.length && (
                    <button className="load-more-button" onClick={loadMoreAssets}>
                        Load More
                    </button>
                )}
            </div>
        </div>
    );
};

export default NFTBurner;
