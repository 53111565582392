import React, { useEffect, useState, useCallback } from 'react';
import "./css/LeaderBoard.css";
import { ContractKit } from '@wharfkit/contract';
import Navbar from './widgets/Navbar';
import Card from './widgets/Card';
import BurnButton from "./widgets/BurnButton"
import eventImg from './widgets/assets/events.jpg';
import Foot from './widgets/Footer';

const LeaderBoard = ({
  session,
  handleLogout,
  balance,
  shards,
  selectedAssets,
  handleSelectAsset,
  setTransactionHash,
  setShowPopup,
  refreshAssets,
  setSelectedAssets,
  toggleSelectAll,
  visibleAssetsCount,
  handleAssetsRefresh
}) => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [visibleEntries, setVisibleEntries] = useState(10); // Initial number of leaderboard entries
  const [visibleShards, setVisibleShards] = useState(18);    // Initial number of shards to display

  // Fetch leaderboard data
  const fetchPointsData = useCallback(async () => {
    if (!session) return;

    const contractKit = new ContractKit({
      client: session.client,
    });

    try {
      const contract = await contractKit.load("nightclubapp");
      const pointsData = contract.table("points").query();
      const rows = await pointsData.all();

      const leaderboardData = rows.map(entry => ({
        account: entry.account?.name || entry.account || '',
        points: entry.points?.value || entry.points || 0
      }));

      leaderboardData.sort((a, b) => b.points - a.points);

      setLeaderboard(leaderboardData);
    } catch (error) {
      console.error('Error fetching points data:', error);
    }
  }, [session]);

  useEffect(() => {
    fetchPointsData();
  }, [fetchPointsData, session]);

  // Function to load more leaderboard entries
  const loadMoreEntries = () => {
    setVisibleEntries(prev => prev + 10); // Load 10 more entries
  };

  // Function to load more shards
  const loadMoreShards = () => {
    setVisibleShards(prev => prev + 12); // Load 6 more shards
  };

  const refreshLeaderboard = async () => {
    // Your logic to fetch leaderboard data
    const contractKit = new ContractKit({
      client: session.client,
    });

    try {
      const contract = await contractKit.load("nightclubapp");
      const pointsData = contract.table("points").query();
      const rows = await pointsData.all();

      const leaderboardData = rows.map(entry => ({
        account: entry.account?.name || entry.account || '',
        points: entry.points?.value || entry.points || 0
      }));

      leaderboardData.sort((a, b) => b.points - a.points);

      // Assuming you have a state to store leaderboard data
      setLeaderboard(leaderboardData);
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
    }
  };

  return (
    <div className='LeaderBoard'>
      {/* Add the Navbar here */}
      <Navbar handleLogout={handleLogout} session={session} balance={balance} />

      <div className="leaderboard-container">
        <div className='leaderboard-layout'>

          <div className="shards-section">
            <div className="event-title">
              <h1 className='halloween'>Halloween Event</h1>
              <p>1 Shard = 10 Points</p>
            </div>

            <div className="shards-grid">
              {shards.slice(0, visibleShards).map((shard) => (
                <Card
                  key={shard.asset_id}
                  asset={shard}
                  small={true}
                  onSelect={handleSelectAsset}
                  isSelected={selectedAssets.includes(shard.asset_id)}

                />
              ))}
            </div>
            <div className='button-case'>

              <button className="select-all" onClick={toggleSelectAll}>
                {selectedAssets.length === shards.slice(0, visibleAssetsCount).length ? 'Deselect All' : 'Select All'}
              </button>
              {visibleShards < shards.length && (
                <button className="load-more-shards" onClick={loadMoreShards}>
                  Load More Shards
                </button>
              )}
              <BurnButton
                data={selectedAssets}
                session={session}
                setTransactionHash={setTransactionHash}
                setShowPopup={setShowPopup}
                refreshAssets={handleAssetsRefresh} // Pass the refresh assets function
                setSelectedAssets={setSelectedAssets}
                refreshLeaderboard={refreshLeaderboard} // Pass the refresh leaderboard function
              />
            </div>

          </div>

          {/* Leaderboard Section */}
          <div className='leaderboard'>
            <img
              src={eventImg}
              alt="Events"
              width="85%"
              borderRadius="10px"
            />
            <h1>Leaderboard</h1>
            {leaderboard.slice(0, visibleEntries).map((entry, index) => (
              <div key={index} className="leaderboard-entry">
                <span>{index + 1}. {String(entry.account)} - {Number(entry.points)} points</span>
              </div>
            ))}
            {visibleEntries < leaderboard.length && (
              <button className="load-more" onClick={loadMoreEntries}>
                Load More Leaderboard Entries
              </button>
            )}
          </div>
        </div>
      </div>
      <Foot />
    </div>
  );
};

export default LeaderBoard;
