import './css/subbar.css';
import waxLogo from './assets/wax.png';
import ncLogo from './assets/nightclublogo.png';


const Subbar = ({ session, balance, handleLogout, items, girls }) => {


    return (
        <nav className="subbar">
            <div className="subbar-left">
                <div className='inv-count'>
                    <div className='inv-grid'>
                        <h4>Girls</h4>
                        <h4>{girls}</h4>
                    </div>
                    <div className='inv-grid'>
                        <h4>Items</h4>
                        <h4>{items}</h4>
                    </div>
                </div>
            </div>
            <div className="subbar-center">
                <h4>{String(session.actor)}</h4>
            </div>
            <div className="subbar-right">
                <div className='user-info-bals'>

                    <div className="user-bals">

                        <h5>
                            {balance && typeof balance === 'object'
                                ? (
                                    <>
                                        <img src={waxLogo} alt="WAX Logo" width="20" height="20" style={{ marginLeft: '5px', marginRight: '5px' }} />
                                        {balance.wax}
                                    </>
                                )
                                : "0.0000 WAX"}
                        </h5>
                        <h5>
                            {balance && typeof balance === 'object'
                                ? (
                                    <>
                                        <img src={ncLogo} alt="SEXY Logo" width="20" height="20" style={{ marginLeft: '5px', marginRight: '5px' }} />
                                        {balance.sexy}

                                    </>
                                )
                                : "0.0000 "}
                        </h5>
                    </div>




                </div>
                <button className="logout-button" onClick={handleLogout}>Logout</button>


            </div>
        </nav>
    );
};



export default Subbar;
